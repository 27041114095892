import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function SpecialismSelectFields({intl, settings, ...rest}) {
  const {additionalData: {specialisms}} = useContext(FormContext);

  const SpecialismField = () => {
    if (!specialisms || !Object.keys(specialisms).length) {
      return null;
    }
    const specialismOptions = [];
    specialismOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    specialisms.forEach((specialism) => {
      specialismOptions.push({
        value: specialism.id,
        label: specialism.name,
      });
    });

    settings.options = specialismOptions;
    return (
      <SelectField
        settings={settings}
        {...rest}
      />
    )
  };

  return (
    <>
      <SpecialismField />
    </>
  )
}

export default injectIntl(SpecialismSelectFields);
