import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";

function Checkbox({intl, settings, onClickLinkCb, ...rest}) {
  const {additionalData} = useContext(FormContext);
  const {label, title, state, labelLink, labelLinkText, secondaryLabelLink, secondaryLabelLinkText, linksConjuction, labelLinkOpenInNewTab, errors, hidden = false, required = true, yesValue = false, emptyValue, skipOnEmpty = false, divClasses = '', getLabelFromContext = ''} = settings || {};
  const name = rest.name || (settings && settings.name);

  if (skipOnEmpty) {
    rest["data-skip-empty-field"] = true;
  }
  if (emptyValue) {
    rest["data-empty-field-value"] = emptyValue;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || (required && errors.hasOwnProperty('generalError'))));
  const id = `field--input--${name}`;

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return (
      <div className="form-group__label">{intl.formatMessage({id: title})}</div>
    )
  };

  const renderLabel = () => {
    if (getLabelFromContext && additionalData?.[getLabelFromContext]) {
      let labelFromContext = additionalData[getLabelFromContext]?.label;
      const tac = additionalData[getLabelFromContext]?.termsAndConditionsLink;
      const privacy = additionalData[getLabelFromContext]?.privacyPolicyLink;
      if (labelFromContext && tac) {
        labelFromContext = labelFromContext.replace('@termsAndConditionsLink', `<a href="${tac.url}" target="_blank">${tac.text}</a>` )
      }
      if (labelFromContext && privacy) {
        labelFromContext = labelFromContext.replace('@privacyPolicyLink', `<a href="${privacy.url}" target="_blank">${privacy.text}</a>` )
      }
      return (
        <span className="selection-control__label">
          <span dangerouslySetInnerHTML={{ __html: labelFromContext}} />
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </span>
      )
    }

    if (!label) {
      return '';
    }

    let link = '';
    let secondaryLink = '';

    if (labelLink && labelLinkText) {
      const linkAttributes = {
        href: intl.formatMessage({id: labelLink}),
        target: (labelLinkOpenInNewTab) ? '_blank' : '_self',
      };

      if (labelLinkOpenInNewTab) {
        linkAttributes["rel"] = "noopener noreferrer";
      }

      if (onClickLinkCb) {
        linkAttributes["onClick"] = onClickLinkCb;
      }

    link = <a {...linkAttributes}>{intl.formatMessage({id: labelLinkText})}</a>
    }

    if (secondaryLabelLink && secondaryLabelLinkText) {
      const linkAttributes = {
        href: intl.formatMessage({id: secondaryLabelLink}),
        target: (labelLinkOpenInNewTab) ? '_blank' : '_self',
      };

      if (labelLinkOpenInNewTab) {
        linkAttributes["rel"] = "noopener noreferrer";
      }

      if (onClickLinkCb) {
        linkAttributes["onClick"] = onClickLinkCb;
      }

    secondaryLink = <a {...linkAttributes}>{intl.formatMessage({id: secondaryLabelLinkText})}</a>
    }
    return (
      <span className="selection-control__label">
        <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: label})}} /> {link && secondaryLink ? intl.formatMessage({id: linksConjuction}, {linkPrimary: link, linkSecondary: secondaryLink}) : link}

        {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
      </span>
    )
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  if (hidden) {
    return '';
  }

  let checked = '';
  if (state && state.hasOwnProperty(name)) {
    if (emptyValue !== state[name]) {
      checked = state[name];
    }
  }

  return (
    <div className={`form-group form-group--selection-control ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      {renderTitle()}
      <div className="form-group__input">
        <label htmlFor={id} className="selection-control selection-control--checkbox">
          <span className="selection-control__input">
            <input
              id={id}
              name={name}
              type="checkbox"
              value={yesValue ? 'yes' : true}
              checked={(checked)}
              required={required}
              {...rest}
            />
            <span className="icon selection-control__control" aria-hidden="true">
              <svg viewBox="0 0 16 16">
                <polyline points="2.1,8.5 6.2,12.4 13.9,5.1"/>
              </svg>
            </span>
          </span>
          {renderLabel()}
        </label>
      </div>
      {renderErrors()}
    </div>
  );

}

export default injectIntl(Checkbox);
